import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from "react-component-component";
import { Darc, Button } from '../../src';
import BeautyRestBrand from '../../src/Icons/BeautyRestBrand';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "darc"
    }}>{`Darc`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Darc  from '@mfrm/mfcl/Darc';
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`The Card component from another point of view.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Darc} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<Component initialState={{}}>\n  {({ setState, state }) => (\n    <Darc style={{ width: 350 }}>\n      <Darc.Image\n        style={{ background: \'#F5F5F5\' }}\n        src=\"https://webapp-product-xray-production.azurewebsites.net/img/mattresses/24 brs900 medium firm.png\"\n        alt=\"Darc image\"\n      />\n      <Darc.Body>\n        <Darc.Title>\n          <BeautyRestBrand />\n        </Darc.Title>\n        <Darc.Text>BRS900 MEDIUM FIRM</Darc.Text>\n        <Darc.Actions style={{ justifyContent: \'space-around\' }}>\n          <Button btnType=\"secondary\">View on Mattress Firm</Button>\n        </Darc.Actions>\n      </Darc.Body>\n    </Darc>\n  )}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Darc,
      Button,
      BeautyRestBrand,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{}} mdxType="Component">
    {({
          setState,
          state
        }) => <Darc style={{
          width: 350
        }} mdxType="Darc">
        <Darc.Image style={{
            background: "#F5F5F5"
          }} src="https://webapp-product-xray-production.azurewebsites.net/img/mattresses/24 brs900 medium firm.png" alt="Darc image" />
        <Darc.Body>
          <Darc.Title>
            <BeautyRestBrand mdxType="BeautyRestBrand" />
          </Darc.Title>
          <Darc.Text>BRS900 MEDIUM FIRM</Darc.Text>
          <Darc.Actions style={{
              justifyContent: "space-around"
            }}>
            <Button btnType="secondary" mdxType="Button">View on Mattress Firm</Button>
          </Darc.Actions>
        </Darc.Body>
      </Darc>}
  </Component>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      